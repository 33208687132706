import React from "react";
import { Link } from "gatsby";
import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    background: #FFF;
  }
`;

const Footer = styled.footer`
  line-height: 28px;
  color: rgba(27, 28, 37, 0.6);
`;

const TextLogo = styled.div`
  font-size:42px;
  color: rgba(43, 45, 54, 0.9);
  font-family: "Major Mono Display", monospace;
  margin-bottom: 32px;
  white-space: pre;
  letter-spacing: -3px;
`;

const Header = () => <header style={{ position: "relative" }}>
  <Link
    style={{
      boxShadow: `none`,
      display: "inline-block",
      height: "auto",
      textDecoration: "none",
    }}
    to={`/`}>
    <TextLogo>{`OVER THE
CODE`}</TextLogo>
  </Link>
</header>;

class Layout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: "800px",
          padding: "32px 16px",
        }}>
        <GlobalStyle />
        <Header />
        <main>{children}</main>
        <Footer>&copy; Karl Saehun Chung</Footer>
      </div>
    );
  }
}

export default Layout;
